import React, { useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import HeadingSection from '../components/HeadingSection/HeadingSection';
import InnovationSection from '../components/InnovstionSection/InnovationSection';
import SolutionSection from '../components/SoltuionSection/SolutionSection';
import OurSolution from '../components/OurSolution/OurSolution';
import OurImpact from '../components/ImpactSection/OurImpact';
import OurJourny from '../components/OurJournySection/OurJourny';
import AwardsSection from '../components/AwardsSection/AwardsSection';
import TeamSection from '../components/TeamSection/TeamSection';
import NewsSection from '../components/NewsSection/NewsSection';
import ViewProduct from '../components/ViewProduct/ViewProduct';
import CompanySection from '../components/CompanySectrion/CompanySection';
import FooterSection from '../components/FooterSection/FooterSection';
import MobileDropdown from '../components/Dropdown/MobileDropdown';
import WhiteMobileBanner from '../components/MobileAppBanner/WhiteMobileAppBanner';

function Home({ isOpen, toggleDropdown }) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			{!isOpen ? (
				<div className='flex flex-col w-full overflow-x-hidden'>
					<Navbar isOpen={isOpen} toggleDropdown={toggleDropdown} />
					<HeadingSection />
					<InnovationSection />
					<SolutionSection />
					<OurSolution />
					<OurImpact />
					<OurJourny />
					<AwardsSection />
					<TeamSection />
					<NewsSection />
					{/* <ViewProduct /> */}
					<WhiteMobileBanner />
					<CompanySection />
					<FooterSection />
				</div>
			) : (
				<MobileDropdown
					toggleDropdown={toggleDropdown}
					className='flex flex-col w-screen h-screen overflow-x-hidden'
				/>
			)}
		</>
	);
}

export default Home;
