import React from 'react';
import BannerWhite from '../../static/BannerWhiteMobile.png';
import BannerBlue from '../../static/BannerMobileblue.png';

export default function WhiteMobileBanner({ color = 'white' }) {
	const redirectToPlayStore = () => {
		const packageName = 'com.naturedots.aquanurch'; // Replace with your app's package name
		const playStoreLink = `https://play.google.com/store/apps/details?id=${packageName}`;
		const intentLink = `intent://${playStoreLink.replace(
			'https://',
			''
		)}#Intent;scheme=https;package=com.android.vending;end;`;

		// Check if the user is on a mobile device
		const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

		if (isMobile) {
			// Redirect to the intent link for mobile devices
			window.open(intentLink, '_blank');
		} else {
			// Fallback to the regular Play Store link for desktop
			window.open(playStoreLink, '_blank');
		}
	};
	return (
		<div
			className='mt-[10px] sm:mt-48 flex sm:flex-row flex-col align-center items-center justify-between bg-white mx-auto sm:px-16 py-[33px] sm:py-0 sm:rounded-2xl cursor-pointer'
			onClick={redirectToPlayStore}
		>
			{color === 'white' ? (
				<img src={BannerWhite} alt='BlogOne' />
			) : (
				<img src={BannerBlue} alt='BlogOne' />
			)}
		</div>
	);
}
